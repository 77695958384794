import React from 'react';
import Planner from './../components/Planner'

const Home = () => {
return (
	<div>
	  <Planner />
	</div>
);
};

export default Home;
